import { useEffect, useState } from 'react';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import LoginView, { loginPostAction } from './views/login/LoginView';
// import EudtrcView, { sendToCompanyWalletPostAction } from './views/EudtrcView';
import Home from './views/Home';
import LogoutView from './views/login/LogoutView';
import AdminView from './views/Admin/AdminView';
import CredentialsView, { sendToCompanyWalletPostAction } from './views/CredentialsView';
import SuomiFiAfterAuthView from './views/login/SuomiFiAfterAuthView';
import LpidView from './views/lpid/LpidView';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 53, 122)'
    }
  },
  typography: {
    h1: { fontSize: '2em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h2: { fontSize: '1.75em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h3: { fontSize: '1.5em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h4: { fontSize: '1.25em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    fontFamily: '"Source Sans Pro", "Helvetica Neue", Arial, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '5em 3em 1em 3em'
        }
      },
      defaultProps: {
        elevation: 0,
        square: true,
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '1em 1em 1em 1em'
        }
      }
    }
  }
});


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    handle: "route.home",
    children: [
      {
        path: "suomifi",
        element: <SuomiFiAfterAuthView />
      },
      {
        path: "login",
        element: <LoginView />,
        action: loginPostAction()
      },
      { 
        path: "admin",
        element: <AdminView />,
        handle: "route.admin"
      },
      {
        path: "lpid/continue",
        element: <p>not implemented yet, todo use authentication data to create lpid credential</p>
      },
      {
        path: "lpid",
        element: <LpidView />,
        handle: "route.lpid",
        action: sendToCompanyWalletPostAction
      },
      {
        path: "credentials",
        element: <CredentialsView />,
        handle: "route.credentials",
        action: sendToCompanyWalletPostAction
      },
      {
        path: "logout",
        element: <LogoutView />,
        handle: "route.logout"
      },
      // {
      //   path: "eudtrc",
      //   element: <EudtrcView />,
      //   handle: "route.eudtrc",
      //   action: sendToCompanyWalletPostAction
      // },
      // {
      //   path: "company",
      //   element: <CompanyView />
      // },
    ],
  },
  {
    path: "*",
    element: <h3>Content not found</h3>
  }
]);

// const userContextContainer: {
//   userContext: IUserData|undefined;
// } = {
//   userContext: undefined
// }

function App() {
  
  return (
    <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
