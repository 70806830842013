import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Heading, Paragraph } from 'suomifi-ui-components'
import vceApi from '../../vceApi';

type Props = {}

const LpidView = (props: Props) => {
  const { t/*, i18n*/ } = useTranslation();


  const [credentialOfferUri, setCredentialOfferUri] = useState<string | undefined>(undefined);

  useEffect(() => {
    let state =  window.crypto.randomUUID().replace(/-/g,"");

    if (credentialOfferUri === undefined) {
      // in case of it have already authenticated, it could use pre-authorized mode...
      vceApi.issuers.issueSuomiFiLpid("test", { useCredentialOfferUri: true }, { credentials: 'include'}).then(async x => {
            
        const url = await x.text()
        console.log(`credentialOfferUri: ${url}`); // todo pitäisiköhän ohjata suomifi tunnistautumiseen? vai mikä tämä oli?
        //x.data.redirectUri = "https://"
        setCredentialOfferUri(url);
        
      }).catch(x => console.log(x));
    }
    
  }, []);


  return (
    <div>
      <Heading variant='h3'>{t("lpidView.title")}</Heading>
      <Paragraph>{t("lpidView.info")}</Paragraph>

      <Box sx={{
      marginTop: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <div>
        {credentialOfferUri &&
          <>
            
            <div style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%" }}>
              <a href={credentialOfferUri}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={credentialOfferUri}
                  viewBox={`0 0 256 256`}
                />
              </a>
              <p style={{textAlign: "center", margin: 0}}>LPID</p>
            </div>
          </>
        }
      </div>
    </Box>
    </div>
  )
}

export default LpidView